window.BIDS = window.BIDS || {};

(function ($) {

    $(document).ready(function () {
        let form = get_stripe_form();
        if (!form || form.length === 0) {
            return;
        }

        let stripe_key = form.data('stripekey');
        let stripe = Stripe(stripe_key)

        let elements = stripe.elements();
        let cardElement = elements.create('card');
        cardElement.mount('#card-element');


        let sub_but = form.find('button[type="submit"]');
        sub_but.on('click', function (e) {
            e.preventDefault();

            let btn = $(this);
            btn.prop('disabled', true);
            let form = $(this).closest('form');

            const cardElementContainer = $('#card-element');

            let card_element_empty = cardElementContainer.hasClass('StripeElement--empty');
            if (!card_element_empty) {
                stripe.createToken(cardElement).then(function (result) {
                    if (typeof result.error != 'undefined') {
                        btn.prop('disabled', false);

                        alert(result.error.message);
                    }

                    if (typeof result.token != 'undefined') {
                        $("#stripe_token_id").val(result.token.id);
                        form.submit();
                    }
                });
            } else {
                form.submit();
            }
        })
    });

    function get_stripe_form() {
        let forms = $('[data-stripekey]');
        if (forms.length > 1) {
            alert('too many stripe forms');
            return null;
        }
        return forms;
    }

})(jQuery);